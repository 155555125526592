<template>
    <members-index
        :company="group"
        :include-fields="['name', 'email', 'createdAt', 'status']"
        :parent="group"
        :role="role"
        :title="$t('groups.clients.nav')"
    />
</template>

<script>
import Role from '@/library/enumerations/Role';
import {Group} from '@/models/vue-mc/Group';
import MembersIndex from '@/pages/shared/Members';

export default {
    name: 'GroupsMembersIndex',
    components: {MembersIndex},
    props: {
        group: {
            type: Group,
            required: false,
            default: undefined,
        },
    },
    data: () => ({
        role: Role.CLIENT,
    }),
};
</script>
